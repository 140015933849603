import StimulusDataTables from 'stimulus-datatables'

export default class extends StimulusDataTables {
  initialize() {
    this.config = {
      paging: true,
      sDom: 'Rrtl',
      searching: true,
      info: false,
      rowReorder: true,
      autoWidth: false,
      fixedHeader: {
        header: true,
        footer: true
      },
      language: {
        emptyTable: "Aucune donnée disponible",
        infoEmpty: "",
        sLengthMenu: '<span class="px-4">Voir <select>'+
        '<option value="10">10</option>'+
        '<option value="25">25</option>'+
        '<option value="50">50</option>'+
        '<option value="-1">tous</option>'+
        '</select>'
      }
    }

    super.initialize()
  }

  connect() {
    if (!this.isBooting()) return
    super.connect()
  }

  teardown() {
    this.log('finished', { dt: this })
    super.teardown()
  }
}
