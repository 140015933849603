import StimulusDataTables from 'stimulus-datatables'
import $ from 'jquery'

export default class extends StimulusDataTables {
  initialize() {
    this.config = {
      paging: true,
      pagingType: 'first_last_numbers',
      sDom: 'Rfrtip',
      searching: true,
      info: true,
      rowReorder: true,
      iDisplayLength: 10,
      processing: true,
      serverSide: true,
      ajax: {
        url: "/organizations/" +
                this.targets.element.dataset.organization +
                "/general_assemblies/" +
                this.targets.element.dataset.ga +
                "/convocation_datatables",
        data: function(d) {
          d.state_filter = $('#state-filter').val();
        },
      },
      columns: [
        { "data": "id" },
        { "data": "dt_sib_state" },
        { "data": "dt_global_status" },
        { "data": "full_name" },
        { "data": "email" },
        { "data": "shares" },
        { "data": "dt_actions" }
      ],
      "columnDefs": [
        {
          "targets": 0,
          "visible": false,
          "searchable": false,
          "orderable": false
        },
        {
          "targets": [1, 2, 6],
          "searchable": false,
          "orderable": false
        },
      ],
      oLanguage: {
        sSearch: ''
      },
      language: {
        info: '_START_ à _END_ sur un total de _TOTAL_',
        emptyTable: 'Aucunes données',
        infoEmpty: "",
        searchPlaceholder: 'Chercher',
        paginate: {
          first: 'Premier',
          last: 'Dernier'
        }
      },
      autoWidth: false,
      fixedHeader: {
        header: true,
        footer: true
      }
    }

    super.initialize()
  }

  connect() {
    if (!this.isBooting()) return
    super.connect()
  }

  teardown() {
    this.log('finished', { dt: this })
    super.teardown()
  }
}

/* Build a simple table with documents meta data */
function format(convId) {
  let base = '<table class="table table-hover tab-org-convos-meta mb-0">'
  var rows = [];
  let meta = $('#convocations-table').data('attributes');

  meta[convId].forEach(
    item => rows.push(
      `<tr><td class="border-0"><b>Statut de l'email : </b>${item['sib_state']}</td>
      <td class="border-0"><b>Email envoyé le : </b>${item['send_date']}</td></tr>`
    )
  )

  return base + rows.join('') + '</table>';
}

/**
 * Code adapted from Datatables' documentation.
 * Add event listener for opening and closing details.
 */
function setup_metadata_toggle() {
  var table = $('#convocations-table').DataTable();

  $('#convocations-table tbody').on('click', 'span.convocation-infos', function () {
    var span_id = $(this).attr('id');
    var td      = $('#' + span_id).parent('td')
    var row     = table.row(td);

    if (row.child.isShown()) {
      // This row is already open - close it
      $("#" + span_id + " .fe-minus-square").addClass('d-none');
      $("#" + span_id + " .fe-plus-square").removeClass('d-none');
      row.child.hide();
      td.removeClass('shown');
    }
    else {
      // Open this row
      $("#" + span_id + " .fe-minus-square").removeClass('d-none');
      $("#" + span_id + " .fe-plus-square").addClass('d-none');
      row.child(format(span_id)).show();
      td.addClass('shown');
    }
  });
}

$(document).ready(function() {
  setup_metadata_toggle();

  $('#state-filter').on('change', function(){
    $('#convocations-table').DataTable().ajax.reload();
  });
});

