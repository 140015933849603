import StimulusDataTables from 'stimulus-datatables'

export default class extends StimulusDataTables {
  initialize() {
    this.config = {
      paging: false,
      sDom: 'Rrtl',
      searching: true,
      info: false,
      rowReorder: true,
      aoColumnDefs: [
        { 'bSortable': false, 'aTargets': ['no-sort'] }
      ],
      language: {
        infoEmpty: "",
        zeroRecords: "Pas de procurations",
        emptyTable: "Aucune donnée disponible"
      }
    }

    super.initialize()
  }

  connect() {
    if (!this.isBooting()) return
    super.connect()
  }

  teardown() {
    this.log('finished', { dt: this })
    super.teardown()
  }
}
