import StimulusDataTables from 'stimulus-datatables'

export default class extends StimulusDataTables {
  initialize() {
    this.config = {
      searching: false,
      paging: true,
      sDom: 'Rfrtl',
      info: false,
      rowReorder: true,
      aoColumnDefs: [
        { 'bSortable': false, 'aTargets': ['no-sort'] }
      ],
      fixedHeader: {
        header: true,
        footer: true
      },
      language: {
        zeroRecords: "Pas d'entreprise correspondante",
        emptyTable: "Aucune donnée disponible",
        sLengthMenu: 'Voir <select>'+
        '<option value="10">10</option>'+
        '<option value="25">25</option>'+
        '<option value="50">50</option>'+
        '<option value="-1">toutes les</option>'+
        '</select> entreprises'
      }
    }

    super.initialize()
  }

  connect() {
    super.connect()
  }

  teardown() {
    this.log('finished', { dt: this })
    super.teardown()
  }
}
