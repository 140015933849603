/*
  Note for CapSens developers.

  Ok for this code, we had an issue with dropdown inside cards and tables. The issue is due to bootstrap
  cards having a 3D transform on its cards leaving any children component on a lower 3D depth and hiding it.

  With this js, we listen for dropdown menus opening and closing to fit the table overflow without changing it
  globally.

  It does the trick that data-bs-boundary couldn't do without expanding the whole parent element of the menu.
*/

$('.table-responsive').on('show.bs.dropdown', function () {
  $('.table-responsive').css( "overflow", "inherit" );
});

$('.table-responsive').on('hide.bs.dropdown', function () {
  $('.table-responsive').css( "overflow", "auto" );
})
