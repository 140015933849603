// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@fortawesome/fontawesome-free/js/all");
require("select2/dist/js/select2");
require('datatables.net');
require.context('../images', true);

import "../src/javascripts/controllers/index.js";
import "../src/javascripts/channels/index.js";
import "../src/javascripts/plugins/index.js";

import "javascripts/theme/theme";

window.jQuery = window.$ = require('jquery');
import "../src/javascripts/theme_custom/general_assembly_charts";
import "../src/javascripts/theme_custom/handle_table_overflow";

jQuery(document).ready(function($) {
  $R('.redactor');

  $(".redactor:input[readonly], .redactor-lazy:input[readonly]").each(function() {
    $R(this, 'enableReadOnly');
  })

});

$('.select2').select2({
  theme: 'bootstrap4',
});
