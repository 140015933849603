import StimulusDataTables from 'stimulus-datatables'

export default class extends StimulusDataTables {
  initialize() {
    this.config = {
      paging: false,
      sDom: 'Rrtl',
      searching: true,
      info: false,
      rowReorder: true,
      aoColumnDefs: [
        { 'bSortable': false, 'aTargets': ['no-sort'] }
      ],
      fixedHeader: {
        header: true,
        footer: true
      },
      language: {
        zeroRecords: "Aucun document disponible",
        emptyTable: "Aucun document disponible",
        infoEmpty: "",
        sLengthMenu: 'Voir <select>'+
        '<option value="10">10</option>'+
        '<option value="25">20</option>'+
        '<option value="50">50</option>'+
        '<option value="-1">Tout</option>'
      }
    }

    super.initialize()
  }

  connect() {
    if (!this.isBooting()) return
    super.connect()
  }
}

/* Build a simple table with documents meta data */
function format(docId) {
  let base = '<table class="table table-hover tab-org-docs-index-meta">'
  var rows = [];
  let meta = $('#documents-index-table').data('attributes');

  meta[docId].forEach(item => rows.push(metaDataRow(item)));

  return base + rows.join('') + '</table>';
}

function metaDataRow(item) {
  switch(item['doc_status']) {
    case 'pending':
      return `
        <tr>
          <td><b>Envoyé à : </b>${item['full_name']}</td>
          <td>${format_email_sending_date(item)}</td>
          <td>${format_signing_date(item)}</td>
          <td></td>
        </tr>`;
    case 'signed':
      return `
        <tr>
          <td><b>Envoyé à : </b>${item['full_name']}</td>
          <td>${format_email_sending_date(item)}</td>
          <td>${format_signing_date(item)}</td>
          <td></td>
        </tr>`;
    case 'sent':
      if (item['type'] == 'signers') {
        return documentWithSignatureRow(item);
      } else {
        return documentWithoutSignatureRow(item);
      }
    default:
      console.log('unknown state: ' + item['doc_status']);
      return `
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>`;
  }
}

function documentWithSignatureRow(item) {
  return `
  <tr>
      <td><b>Envoyé à : </b>${item['full_name']}</td>
      <td>${format_email_sending_date(item)}</td>
      <td>${format_signing_date(item)}</td>
      <td>${format_document_sending_date(item)}</td>
    </tr>`;
}

function documentWithoutSignatureRow(item) {
  return `
    <tr>
      <td><b>Envoyé à : </b>${item['full_name']}</td>
      <td>${format_email_sending_date(item)}</td>
      <td></td>
      <td>${format_document_sending_date(item)}</td>
    </tr>`;
}


function format_document_sending_date(item) {
  let sendingDate = item['doc_sent_at'];

  if (sendingDate) {
    return `Document envoyé le ${sendingDate}`;
  }

  return '';
}

function format_email_sending_date(item) {
  let sendingDate = item['sending_date'];

  if (sendingDate) {
    return `<b>Email envoyé le :</b> ${sendingDate}`;
  }

  return '';
}

function format_signing_date(item, leaveEmpty = false) {
  let send_date = item['signing_date'];

  if (send_date) {
    return `<b>Statut de l'email : </b>Signé le ${send_date}`;
  }

  if (leaveEmpty) {
    return '';
  }

  return "<b>Statut de l'email : </b>En attente de signature";
}

function setup_metadata_toggle() {
  var table = $('#documents-index-table').DataTable();

  $('.doc-infos').on('click', function () {
    var meta  = $('#documents-index-table').data('attributes');
    var tr_id = $(this).attr('id');

    if (meta[tr_id] != undefined) {
      var tr    = $('#' + tr_id).closest('tr')
      var row   = table.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        $("#" + tr_id + " td .fe-minus-square").addClass('d-none');
        $("#" + tr_id + " td .fe-plus-square").removeClass('d-none');
        row.child.hide();
        tr.removeClass('shown');
      }
      else {
        // Open this row
        $("#" + tr_id + " td .fe-minus-square").removeClass('d-none');
        $("#" + tr_id + " td .fe-plus-square").addClass('d-none');
        row.child(format(tr_id)).show();
        tr.addClass('shown');
      }
    }
  });
}

$(document).ready(function() {
  setup_metadata_toggle();
});
