import StimulusDataTables from 'stimulus-datatables'
import $ from 'jquery'

export default class extends StimulusDataTables {
  initialize() {
    this.config = {
      paging: true,
      sDom: 'Rfrtl',
      searching: false,
      info: false,
      rowReorder: true,
      columnDefs: [
        {
          "targets": 4,
          "searchable": false,
          "orderable": false
        },
      ],
      language: {
        zeroRecords: "Pas de membres correspondantes",
        emptyTable: "Aucune donnée disponible",
        infoEmpty: "",
        sLengthMenu: 'Voir <select>'+
        '<option value="10">10</option>'+
        '<option value="25">25</option>'+
        '<option value="50">50</option>'+
        '<option value="-1">tous les</option>'+
        '</select> membres'
      }
    }

    super.initialize()
  }

  connect() {
    if (!this.isBooting()) return
    super.connect();

    var table = $('#liveVoteTable').DataTable();
    table.columns.adjust().draw();
  }

  teardown() {
    this.log('finished', { dt: this })
    super.teardown()
  }

  check_all_stakeholders() {
    var table = $('#liveVoteTable').DataTable();
    var chk   = event.currentTarget.checked;
    $('.invit-all:not([disabled])', table.rows().nodes()).prop('checked', chk);
  }
}

$('document').ready(function() {
  $('#liveVoteStepOneForm').on('submit', function(e) {
    $('#liveVoteTable').DataTable().destroy();
    $('#liveVoteTable').addClass('d-none');
    $('#sendingInProgress').removeClass('d-none');
  });
})

