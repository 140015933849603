import StimulusDataTables from 'stimulus-datatables'

/**
 * /!\ WARNING /!\
 * CSS selector used to fetch state from each of the datatable's rows.
 * If html is updated (table), be sure that this css selector is still valid.
 */
const STATE_SELECT = 'td.sorting_1 > span';

/**
 * Build filter options by updating select tag.
 */
function build_filter_options(select, selectedValue) {
  let filter_values = [
    'À venir',
    'Clôturée',
    'En cours',
    'Passée'
  ];

  filter_values.forEach(function(val) {
    if (val === selectedValue) {
      select.append(`<option value="${val}" selected="selected">${val}</option>`);
    } else {
      select.append(`<option value="${val}">${val}</option>`);
    }
  })
}

/**
 * Build select tag: insert tag, add callback on change and build options.
 */
function build_select(context) {
  let selectFilterId = '#state-filter';
  let selectedValue = $(`${selectFilterId} select`)
    .children('option:selected')
    .val();

  /*
   * Remove <select> from filter.
   * If user goes to another and comes back, the page content stays the
   * same, but the JS code is re-executed: this function will add another
   * <select> tag inside the same <div>.
   */
  $(selectFilterId).empty();

  let selector = '<select><option value="">Filtre</option></select>';
  let select = $(selector).appendTo(selectFilterId).on('change', function() {
    let content = $(this).val();
    var val = $.fn.dataTable.util.escapeRegex(content);

    context.search( val ? `^${val}$` : '', true, false ).draw();
  });

  build_filter_options(select, selectedValue);
}

export default class extends StimulusDataTables {
  initialize() {
    this.config = {
      paging: true,
      sDom: 'Rrtl',
      searching: true,
      info: false,
      rowReorder: true,
      autoWidth: false,
      aoColumnDefs: [
        { 'bSortable': false, 'aTargets': ['no-sort'] }
      ],
      "order": [],
      initComplete: function () {
        let context = this.api().columns(0);
        build_select(context);

        // When paging changes, draw event is triggered: use it to update
        // the filter
        this.on('draw.dt', function() { build_select(context) });
      },
      fixedHeader: {
        header: true,
        footer: true
      },
      // Be sure that these values matches the ones from sLengthMenu.
      // On page load, the pagination value won't be the first sLengthMenu
      // value, but lengthMenu[0] (which default value is 10).
      lengthMenu: [ 10, 25, 50, -1 ],
      language: {
        zeroRecords: "Pas d'instances correspondantes",
        emptyTable: "Aucune donnée disponible",
        infoEmpty: "",
        sLengthMenu: 'Voir <select>'+
        '<option value="10">10</option>'+
        '<option value="25">25</option>'+
        '<option value="50">50</option>'+
        '<option value="-1">toutes les</option>'+
        '</select> instances'
      }
    }

    super.initialize()
  }

  connect() {
    if (!this.isBooting()) return
    super.connect()
  }

  teardown() {

    this.log('finished', { dt: this })
    super.teardown()
  }
}
