import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    "content",
    "fill",
    "up",
    "down"
  ]

  connect() {
    this.init_redactor();

    this.set_location();
    this.update_location();
  }

  init_redactor() {
    // Needed to clone motions (li tags).
    this.baseMotionNode = document.getElementById('li-motion-0').cloneNode(true);
    this.baseMotionNode.setAttribute('id', 'li-motion-base');

    // on edit, textarea will not be empty ; we don't want to have a base
    // motion node with some content.
    let text = this.baseMotionNode.querySelector('textarea');
    text.value = '';

    // Apply redactor plugin once our base motion node is ready
    $R('.redactor-lazy');
  }

  set_location() {
    let target = '#general_assembly_ga_location_type';
    let func = this.update_location;

    $(target).on('change load', func);
  }

  update_location() {
    let location_type          = $('#general_assembly_ga_location_type');
    let selected_location_type = location_type.val();

    if (selected_location_type == 'physical') {
      $('#ga_location_input').removeClass('d-none');
      $('#virtual_link_input').addClass('d-none');
    } else if (selected_location_type == 'physical_and_virtual') {
      $('#ga_location_input').removeClass('d-none');
      $('#virtual_link_input').removeClass('d-none');
    } else if (selected_location_type == 'virtual') {
      $('#ga_location_input').addClass('d-none');
      $('#virtual_link_input').removeClass('d-none');
    } else {
      $('#ga_location_input').addClass('d-none');
      $('#virtual_link_input').addClass('d-none');
    }
  }

  move_li_up() {
    var li_list = $('li[id^="li-motion-"]');
    var li = this.up_to_li(event.target);

    if (li === null) {
      console.log(`move_li_up: could not find <li> tag: ${event.target}`);
      return;
    }

    if (li != li_list[0]) {
      var prev_li = $(li).prevAll('li').first();
      prev_li.before($(li));
      updateOrderValues();
    }

    setIndex();
  }

  move_li_down() {
    var li_list = $('li[id^="li-motion-"]');
    var length = li_list.length;
    var li = this.up_to_li(event.target);

    if (li === null) {
      console.log(`move_li_down: could not find <li> tag: ${event.target}`);
      return;
    }

    if (li != li_list[length-1]) {
      var next_li = $(li).nextAll('li').first();
      next_li.after($(li));
      updateOrderValues();
    }

    setIndex();
  }

  /**
   * Move up to first <li> tag
   * @param {object}: start, html node
   * @return {object}: li tag, null if no tag found
   */
  up_to_li(tag) {
    for(; (tag != null) && (!this.is_li(tag)); tag = tag.parentNode) {}
    return tag;
  }

  is_li(tag) {
    return tag.tagName.toLowerCase() == 'li';
  }
}

function onlineToggle(target) {
  target.each(function() {
    if($(this).is(':checked')) {
      let selected = $(this).val();

      // Toggle address input
      if (selected == 'online') {
        document.getElementById('ga_time_input').classList.add('d-none');
        document.getElementById('ga_location_input').classList.add('d-none');
      } else {
        document.getElementById('ga_time_input').classList.remove('d-none');
        document.getElementById('ga_location_input').classList.remove('d-none');
      }

      // Update placeholder
      let locationLabel = document.getElementById('ga_location_label');
      let locationInput = document.getElementById('ga_location_type_input_field');
      if (selected == 'physical') {
        locationLabel.innerHTML = 'Adresse physique';
        locationInput.placeholder = 'Adresse physique...';
      } else {
        locationLabel.innerHTML = 'Lien visio';
        locationInput.placeholder = 'Lien visio...';
      }
    }
  });
}

function setIndex() {
  var p_list      = $('span[id^="content-label-"]');
  var type_select = $('select[id^="type-select-"]');
  var min_max_div = $('div[id^="min-max-block-"]');
  var answer_form = $('div[id^="answer-form-block-"]');
  var unique_ans = $('p[id^="unique-"]');

  for (var i = 0; i < p_list.length; i++) {
    p_list[i].id      = `content-label-${i}`;
    type_select[i].id = `type-select-${i}`;
    min_max_div[i].id = `min-max-block-${i}`;
    answer_form[i].id = `answer-form-block-${i}`;
    unique_ans[i].id = `unique-${i}`;

    $('#content-label-' + i).text(i + 1);
  }
}

function setMotionsType() {
  $('.select-type').on('change', function() {
    var currentId = this.id.match(/\d+/)[0];

    if(this.value == "Motion::MultipleChoiceQuestion") {
      $('#answer-form-block-' + currentId).removeClass('d-none');
      $('#min-max-block-'+ currentId).removeClass('d-none');
      $('#unique-'+ currentId).addClass('d-none')
    } else {
      $('#answer-form-block-' + currentId).addClass('d-none');
      $('#min-max-block-'+ currentId).addClass('d-none');
      $('#unique-'+ currentId).removeClass('d-none')
    }
  })

  $('.select-type').change();
}

function updateOrderValues() {
  var order_list = $('input[id$="_order"]');
  for (var i = 0; i < order_list.length; i++) {
    order_list[i].value = i + 1;
  }
}

function callNeededMethods() {
  setIndex();
  setMotionsType();
  updateOrderValues();
}

$('document').ready(function() {
  let locationType = $("input[name='general_assembly[ga_location_type]']");

  // online
  if (locationType.length) {
    onlineToggle(locationType);

    locationType.on('click', function(e) {
      onlineToggle(locationType);
    })
  }

  callNeededMethods();

  $('#motions').on('cocoon:after-insert', function() {
    $R('.redactor-lazy');
    callNeededMethods();
  });

  $('#motions').on('cocoon:after-remove', function() {
    callNeededMethods();
  });
});
