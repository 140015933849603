// custom chart
function initChart(element) {
  const elementId = element.id;
  const data_vote = element.getAttribute('data-chart-vote');
  const data_delegate = element.getAttribute('data-chart-delegate');
  const data_not_answered = element.getAttribute('data-chart-not-answered');
  const bgColors = ['#00a660', '#a7a7a7', '#f4b312'];
  const labels = ['Vote(s)', 'Pouvoir(s)', 'Sans réponse(s)'];

  new Chart(elementId, {
    type: 'doughnut',
    options: {
      aspectRatio: 2,
      plugins: {
        tooltip: {
          callbacks: {
            afterLabel: function() {
              return '%'
            }
          }
        }
      }
    },
    data: {
      labels: labels,
      datasets: [{
        data: [data_vote, data_delegate, data_not_answered],
        backgroundColor: bgColors,
      }]
    }
  });
};

const quorumPerSharesChart = document.getElementById('quorumPerSharesChart');

if (quorumPerSharesChart) {
  initChart(quorumPerSharesChart);
}

const quorumPerMemberChart = document.getElementById('quorumPerMemberChart');

if (quorumPerMemberChart) {
  initChart(quorumPerMemberChart);
}
