$(document).ready(function() {
  $('#weightSelect').on('change', function() {
    if(this.value == 'without_weight') {
      $('.with_weight').addClass("d-none");
      $('.without_weight').removeClass("d-none");
    } else {
      $('.without_weight').addClass("d-none");
      $('.with_weight').removeClass("d-none");
    }
  })
})
