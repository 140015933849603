import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "type", "name", "siren" ]

  connect() {
    this.type_changed()
  }

  type_changed() {
    if ($(this.typeTarget).val() == 'Stakeholder::Individual') {
      $(this.nameTarget).hide()
      $(this.sirenTarget).hide()
    }
    else {
      $(this.nameTarget).show()
      $(this.sirenTarget).show()
    }
  }
}
