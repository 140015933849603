import DataTable from 'stimulus-datatables'

export default class extends DataTable {
  initialize() {
    this.config = {
      paging: false,
      searching: false,
      info: false,
      fixedHeader: {
        header: true,
        footer: false
      },
      language: {
        emptyTable: "Aucune donnée disponible",
        infoEmpty: ""
      }
    }

    super.initialize()
  }

  connect() {
    if (!this.isBooting()) return
    super.connect()
  }

  teardown() {
    this.log('finished', { dt: this })
    super.teardown()
  }
}
