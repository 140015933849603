$('document').ready(function() {
  var counter = setInterval(function() { setCounter(); }, 1000);

  $('#pauseButton').on('click', function() {
    $(this).addClass('d-none');
    $('#playButton').removeClass('d-none');

    if (counter != undefined) {
      clearInterval(counter);
      counter = undefined;
    }
  });

  $('#playButton').on('click', function(e) {
    e.stopPropagation();

    $(this).addClass('d-none');
    $('#pauseButton').removeClass('d-none');

    if (counter == undefined) {
      counter = setInterval(function() { setCounter(); }, 1000);
    }
  });
})

function setCounter() {
  var counterValue = parseInt($('#refreshPageCounter').text());

  if(counterValue == 1) {
    $('#refreshPageCounter').text(0);
    location.reload();
  } else {
    decreaseCounter(counterValue);
  }
}

function decreaseCounter(counterValue) {
  $('#refreshPageCounter').text(Math.max(0, counterValue - 1));

  if(counterValue <= 11) {
    $('#refreshPageCounter').css( "color", "red" );
  }
}
