import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    "conjoint",
    "stakeholder",
    "stakeholderDiv",
    "procAnswer",
    "conjointField"
  ]

  connect() {
    let convId = this.stakeholderTarget.dataset.convId;

    $('#stakeholderSelectAjax').select2({
      allowClear: true,
      placeholder: "Entrez le nom d'un membre",
      ajax: {
        type: 'GET',
        dataType: 'json',
        url: "/convocations/delegate/stakeholders_meta?id=" + convId,
        data: function(params) {
          return { q: params.term }
        },
        processResults: function (data, params) {
          return {
            results: $.map(data, function(value, index){
              return {id: value.id, text: value.first_name + ' ' + value.last_name }
            })
          };
          cache: true
        }
      }
    });

    this.display_proc_form();
  }

  display_proc_form() {
    if(this.procAnswerTarget.value == 'conjoint_procuration') {
      this.conjointTarget.classList.remove("d-none")
      this.stakeholderDivTarget.classList.add("d-none")
      this.stakeholderTarget.selectedIndex = -1
    } else if(this.procAnswerTarget.value == 'stakeholder_procuration') {
      this.stakeholderDivTarget.classList.remove("d-none")
      this.conjointTarget.classList.add("d-none")
      this.conjointFieldTargets.forEach(e => e.value = "")
    } else if (this.procAnswerTarget.value == 'president_procuration') {
      this.stakeholderDivTarget.classList.add("d-none")
      this.conjointTarget.classList.add("d-none")
      this.conjointFieldTargets.forEach(e => e.value = "")
      this.stakeholderTarget.selectedIndex = -1
    } else {
      this.stakeholderDivTarget.classList.add("d-none")
      this.conjointTarget.classList.add("d-none")
      this.conjointFieldTargets.forEach(e => e.value = "")
      this.stakeholderTarget.selectedIndex = -1
    }
  }
}

function toggleValidatePresent(radioPresent, validatePresent, validate) {
  if (radioPresent.prop('checked')) {
    validatePresent.classList.remove('d-none');
    validate.classList.add('d-none');
  } else {
    validatePresent.classList.add('d-none');
    validate.classList.remove('d-none');
  }
}

function toggleBorder(addTo, removeFrom1, removeFrom2) {
  if (addTo) {
    addTo.classList.add('convocations-choice');
    addTo.classList.remove('convocations-choice-border');
  }

  if (removeFrom1) {
    removeFrom1.classList.remove('convocations-choice');
    removeFrom1.classList.add('convocations-choice-border');
  }

  if (removeFrom2) {
    removeFrom2.classList.remove('convocations-choice');
    removeFrom2.classList.add('convocations-choice-border');
  }
}

$(document).ready(function() {
  let radioPresent = $('#convocation_form_choice_present');
  let radioDelegate = $('#convocation_form_choice_delegate');
  let radioVote = $('#convocation_form_choice_vote');

  if (radioPresent.length || radioDelegate.length || radioVote.length) {

    let validatePresent = document.getElementById('validate-present-btn');
    let validate        = document.getElementById('validate-btn');

    let radioPresentImg = document.getElementById('convocations-edit-choice-present');
    let radioDelegateImg = document.getElementById('convocations-edit-choice-delegate');
    let radioVoteImg = document.getElementById('convocations-edit-choice-vote');

    if (radioPresent.length) {
      radioPresent.on('change', function(e) {
        if(validatePresent != null) {
          toggleValidatePresent(radioPresent, validatePresent, validate);
        }
        toggleBorder(radioPresentImg, radioDelegateImg, radioVoteImg);
      })
    }

    if (radioDelegate.length) {
      radioDelegate.on('change', function(e) {
        toggleValidatePresent(radioPresent, validatePresent, validate);
        toggleBorder(radioDelegateImg, radioPresentImg, radioVoteImg);
      })
    }

    if (radioVote.length) {
      radioVote.on('change', function(e) {
        toggleValidatePresent(radioPresent, validatePresent, validate);
        toggleBorder(radioVoteImg, radioPresentImg, radioDelegateImg);
      })
    }
  }
})
