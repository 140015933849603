import StimulusDataTables from 'stimulus-datatables'

export default class extends StimulusDataTables {
  initialize() {
    this.config = {
      paging: true,
      sDom: 'Rfrtip',
      searching: true,
      pagingType: 'first_last_numbers',
      iDisplayLength: 10,
      info: true,
      rowReorder: true,
      processing: true,
      serverSide: true,
      ajax: {
        url: "/organizations/" +
             this.targets.element.dataset.organization +
             "/stakeholders/datatables_stakeholders?type=" +
             this.targets.element.id
      },
      columns: [
        { "data": "full_name" },
        { "data": "marker" },
        { "data": "type" },
        { "data": "email" },
        { "data": "shares" },
        { "data": "voter" },
        { "data": "dt_actions" }
      ],
      oLanguage: {
        sSearch: ''
      },
      language: {
        info: '_START_ à _END_ sur un total de _TOTAL_',
        emptyTable: 'Aucunes données',
        infoEmpty: "",
        searchPlaceholder: 'Chercher',
        paginate: {
          first: 'Premier',
          last: 'Dernier'
        }
      },
      autoWidth: false,
      fixedHeader: {
        header: true,
        footer: true
      }
    }

    super.initialize()
  }

  connect() {
    if (!this.isBooting()) return
    super.connect()
  }

  teardown() {
    this.log('finished', { dt: this })
    super.teardown()
  }
}
