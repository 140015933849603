import StimulusDataTables from 'stimulus-datatables'

export default class extends StimulusDataTables {
  initialize() {
    this.config = {
      paging: true,
      sDom: 'Rfrtl',
      searching: false,
      info: false,
      rowReorder: true,
      aoColumnDefs: [
        { 'bSortable': false, 'aTargets': ['no-sort'] }
      ],
      fixedHeader: {
        header: true,
        footer: true
      },
      language: {
        zeroRecords: "Pas d'actionnaires correspondants",
        emptyTable: "Aucune donnée disponible",
        infoEmpty: "",
        sLengthMenu: 'Voir <select>'+
        '<option value="10">10</option>'+
        '<option value="25">20</option>'+
        '<option value="50">50</option>'+
        '<option value="-1">Tout</option>'
      }
    }

    super.initialize()
  }

  connect() {
    if (!this.isBooting()) return
    super.connect()
  }

  teardown() {
    this.log('finished', { dt: this })
    super.teardown()
  }
}
