import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "last_name", "first_name" ]

  connect() {
    $(this.last_nameTarget).select2(
      {
				allowClear: true,
				placeholder: {
				  id: "",
				  text:"",
				  selected:'selected'
				},
				closeOnSelect: true,
				minimumInputLength: 2,
				ajax: {
          url: window.location.href,
          dataType: 'json',
			  	data: function (params) {
				    console.log("=> query")
				    var query = {
				      format: 'json',
				      q: { last_name_cont: params.term }
				    }
				    return query;
				  },
				  processResults: function (data) {
				    var results = data.map(profile => ({ id: profile.last_name, text: profile.last_name }))
				    return { results }
				  }
				}
      }
    )
  }
}
