$('document').ready(function() {
  if($('#customSwitch5').length <= 0) return;

  $('#customSwitch5').on('change', function() {
    if(!$(this).data('has-president') && $(this).is(':checked')) {
      $('#presidentWarning').removeClass('d-none');
    } else {
      $('#presidentWarning').addClass('d-none');
    }
  })
})
